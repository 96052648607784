import Framework, { shapes } from '@greenville/framework';
import { Grid, Paper, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TablePagination from '@mui/material/TablePagination';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import DialogComponent from '../../../../../common/components/Dialog';
import TableComponent from '../../../../../common/components/Table';
import ChatgptConfig from '../../../../../common/config/ChatgptConfig';
import ColumnConfig from '../../../../../common/config/ColumnConfig';
import * as constants from '../../../../../common/constants';
import utils from '../../../../../common/utils';
import ChannelsModel from '../../../models/ChannelsModel';
import ChatgptUtilityIESUser from '../../../models/ChatgptIESUser';
import ChatgptUtilityMapping from '../../../models/ChatgptMapping';
import SubTypeResponse from '../../../models/SubTypeModel';

const styles = () => ({
  tableColumnHeight: {
    maxHeight: '600px',
    overflow: 'hidden',
    // overflowY: 'scroll',
    // borderRadius: '2px',
    // border: '1px solid #0003',
    marginBottom: '2%'
  },
  highlighted: {
    backgroundColor: '#8080802e'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600',
    textAlign: 'center'
  },
  tableLeftScroll: {
    maxHeight: '600px',
    overflow: 'hidden',
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid #0003'
  },
  columnData: {
    color: '#6a7070',
    fontSize: '14px',
    textAlign: 'center'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  iconStyle: {
    display: 'flex',
    paddingLeft: '12px',
    cursor: 'pointer',
    alignItems: 'center'
  },
  fontStyle: {
    marginLeft: '10px'
  }
});

const ChannelsListings = (props) => {
  const {
    classes,
    ChatgptUtilityIESUserData,
    channelsListingUtilityData,
    chatgptMappingUtility,
    channelsSubType,
    channelsModelData,
    modelStatus
  } = props;
  const { chatListDatas } = channelsListingUtilityData;
  const [highLight, setHighLght] = useState(-1);
  const [trendsList, setTrendsList] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = React.useState({});
  const [clusterTable, setClusterTable] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [showTable, setshowTable] = useState(false);
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = React.useState('');
  const [openChat, openChatDialog] = React.useState(false);
  const [dataValue, setDataValue] = useState({});
  const [userTocDetails, setUserTocDetails] = useState({});
  const [dataLength, setDatalength] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [subTypeData, setSubTypeData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromValue, setFromValue] = useState(0);

  const loadingStatus = modelStatus.isPending;
  const isErrorValue =
    modelStatus.isError && modelStatus.error && modelStatus.error.status === 401 && modelStatus.error.status === 500;
  const errorDisplayVal = modelStatus.error;
  const loaderParams = {
    loadingStatus,
    isErrorValue,
    errorDisplayVal
  };

  useEffect(() => {
    const combinedObjects = {};
    setshowTable(false);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });

    if (!_.isEmpty(chatListDatas) && chatListDatas.data.length > 0) {
      const filteredContent = chatListDatas.data.filter((item) => item.type !== constants.PROXY_CONTENT_FILTER);
      filteredContent.forEach((obj) => {
        const key = obj.clusterName && obj.clusterName.split('').sort().join('');
        if (combinedObjects[key]) {
          combinedObjects[key].count += 1;
        } else {
          combinedObjects[key] = { name: obj.clusterName, count: 1 };
        }
      });
      if (Object.values(combinedObjects).length > 0) {
        const pushToLast = Object.values(combinedObjects);
        const filteredItems = pushToLast.filter((item) => item.name !== constants.OTHER_TOPICS);
        const itemToMove = pushToLast.find((item) => item.name === constants.OTHER_TOPICS);
        const resultArray =
          filteredItems &&
          filteredItems
            .sort((a, b) => b.count - a.count)
            .map(({ name, count }) => (count > 1 ? `${name} (${count})` : name));
        const updatedItems = !_.isEmpty(itemToMove)
          ? [...resultArray, `${itemToMove.name} (${itemToMove.count})`]
          : resultArray;
        setTrendsList(updatedItems);
      }
    }
    Framework.getEventManager().on(constants.SET_CHATGPT_MAPPING_DATA, () => {
      const { toc } = chatgptMappingUtility;
      if (toc.length > 0) {
        setUserTocDetails(toc);
      }
    });
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.CHANNELS_SUB_TYPE_RESPONSE, () => {
      const resData = utils.processChannelsSubTypeResponse(channelsSubType);
      setSubTypeData(resData);
    });
    Framework.getEventManager().on(constants.CHANNELS_MODEL_RESPONSE, () => {
      const resData = utils.processChannelsSubTypeResponse(channelsModelData);
      setLoaderStatus(false);
      if (resData.length > 0) {
        const [record] = resData;
        setDataValue(record);
      } else {
        setDataValue({});
      }
    });
  }, []);

  function getObjectName(array, targetName) {
    const matchObjects = array.filter(
      (obj) => obj.clusterName === targetName && obj.type !== constants.PROXY_CONTENT_FILTER
    );
    return matchObjects.map(
      ({ serverDateTime, userCommand, dateStamp, channelsId, userId, title, type, requestId }) => ({
        serverDateTime,
        userCommand,
        dateStamp,
        channelsId,
        userId,
        type,
        title,
        requestId
      })
    );
  }

  const handleList = (item, listIndex) => {
    setHighLght(listIndex);
    ChatgptConfig.channelsTypeData.forEach((value) => {
      const alteredValue = value.name;
      if (!alteredValue) return;
      chatListDatas.data.forEach((val, index) => {
        if (val.type === alteredValue) {
          chatListDatas.data[index].title = value.label;
        }
      });
    });
    const trimmedName = item.replace(/\(\d+\)/g, '').trim();
    setClusterName(trimmedName);
    setshowTable(true);
    const matchingNamesArray = getObjectName(chatListDatas.data, trimmedName);
    const sortedArray = matchingNamesArray.sort((x, y) => new Date(y.dateStamp) - new Date(x.dateStamp));
    setPage(0);
    setClusterTable(sortedArray);
  };

  const handleAscending = (dir, value) => {
    if (dir === 'asc') {
      const sorted = [...clusterTable].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      setClusterTable(sorted);
    } else {
      const sorted = [...clusterTable].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      setClusterTable(sorted);
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((item) => item.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const handleEvent = (params, index) => {
    const { requestId, userId, type } = params;
    setDatalength(clusterTable.length);
    openChatDialog(true);
    setModalIndex(fromValue + index);
    setLoaderStatus(true);
    channelsModelData.fetch({ type, requestId, userId });
  };

  const handleClose = () => {
    openChatDialog(false);
    setModalIndex(1);
    setSubTypeData([]);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      const { requestId, userId, type } = clusterTable[modalIndex - 1];
      setModalIndex(modalIndex - 1);
      setLoaderStatus(true);
      setSubTypeData([]);
      channelsModelData.fetch({ type, requestId, userId });
    } else {
      const { requestId, userId, type } = clusterTable[modalIndex + 1];
      setModalIndex(modalIndex + 1);
      setLoaderStatus(true);
      setSubTypeData([]);
      channelsModelData.fetch({ type, requestId, userId });
    }
  };

  const handleSelectedCheckBox = (value, sourceType) => {
    const { userId, requestId } = dataValue;
    if (subTypeData.length > 0) {
      const findValue = subTypeData.find((data) => data.requestId === requestId && data.type === sourceType);
      if (!findValue) {
        channelsSubType.fetch({ userId, type: value, requestId });
      }
    } else {
      channelsSubType.fetch({ userId, type: value, requestId });
    }
  };

  const onColumnClick = (val) => {
    sorting(val);
  };
  const onRowClick = (val, index) => {
    handleEvent(val, index);
  };
  const onMouseEnter = (val) => {
    hanldeGETUserId(val);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFromToValueForPagination = (from, to, count) => {
    setFromValue(from - 1);
    return `${from}-${to} of ${count}`;
  };

  return (
    <>
      {trendsList.length > 0 && (
        <Grid container direction="row">
          {trendsList.length > 0 && (
            <Grid item xs={7}>
              <br />
              <Typography align="left" variant="h3">
                {constants.CLUSTER_LIST}
              </Typography>
              <br />
            </Grid>
          )}
          <Grid item xs={5}>
            <br />
            <Typography align="left" variant="h3">
              {showTable && clusterName}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={4} className={classes.tableColumnHeight}>
            {trendsList.length > 0 && (
              <Box
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  height: '100%',
                  border: '1px solid #0003',
                  overflowY: 'scroll',
                  borderRadius: '2px'
                }}
              >
                {trendsList.length > 0 &&
                  trendsList.map((item, index) => (
                    <div key={index} className={index === highLight ? classes.highlighted : ''}>
                      <div
                        className={classes.iconStyle}
                        onClick={() => handleList(item, index)}
                        onKeyDown={() => handleList(item)}
                        role="button"
                        tabIndex={0}
                      >
                        <LightbulbIcon sx={{ color: '#0003' }} />
                        <p className={classes.fontStyle}>{item}</p>
                      </div>
                    </div>
                  ))}
                <Divider />
              </Box>
            )}
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ width: '100%' }}>
              {showTable && (
                <Paper sx={{ p: 2 }} className={classes.tableLeftScroll}>
                  <TableComponent
                    columns={ColumnConfig.ChannelsListingDataGridColumn}
                    data={clusterTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    onColumnClick={onColumnClick}
                    order={order}
                    onRowClick={onRowClick}
                    directionValue={directionValue}
                    userEmailDetails={userEmailDetails}
                    onMouseEnter={onMouseEnter}
                    showSelectColumn
                  />
                  <TablePagination
                    component="div"
                    count={clusterTable.length}
                    labelDisplayedRows={({ from, to, count }) =>
                      useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
                    }
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ display: 'ruby-text' }}
                  />
                </Paper>
              )}
            </Box>
          </Grid>
          <br />
          <br />
          {openChat && (
            <DialogComponent
              openChat={openChat}
              dataValue={dataValue}
              userTocDetails={userTocDetails}
              dataLength={dataLength}
              modalIndex={modalIndex}
              handleClose={handleClose}
              handleModalChange={handleModalChange}
              subTypeData={subTypeData}
              handleSelectedCheckBox={handleSelectedCheckBox}
              loaderStatus={loaderStatus}
              loaderParams={loaderParams}
            />
          )}
        </Grid>
      )}
    </>
  );
};

ChannelsListings.propTypes = {
  channelsListingUtilityData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptMappingUtility: shapes.modelOf(ChatgptUtilityMapping).isRequired,
  channelsSubType: shapes.modelOf(SubTypeResponse),
  channelsModelData: shapes.modelOf(ChannelsModel),
  modelStatus: shapes.state.isRequired
};

ChannelsListings.defaultProps = {
  channelsSubType: [],
  channelsModelData: []
};

export default withStyles(styles)(
  observer(
    inject(
      'ChatgptUtilityIESUserData',
      'chatgptMappingUtility',
      'channelsModelData',
      'modelStatus',
      'channelsSubType'
    )(ChannelsListings)
  )
);
