import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class EffectivenessMetadataService extends BaseService {
  constructor() {
    super(constants.GET_STUDYTOOL_EFFECTIVENESS_METADATA, 'EffectivenessMetaDataStatus');
  }

  handleEvent(eventName, event) {
    return this.getClient('evergreen').post(`/chatutility/effectiveness/metadata`, event.payload);
  }

  handleResponse(response) {
    const { data } = response;
    if (data.status === 'success') {
      // const notification = {
      //   open: true,
      //   type: 'success',
      //   message: 'Data Fetched successfully'
      // };
      Framework.getStoreRegistry().getStore('effectivenessData').setMetaDataResponse(response.data);
      // Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
      if (status === 500 && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    } else if (error.message === 'timeout of 60000ms exceeded') {
      const notification = {
        open: true,
        message: error.message,
        type: 'error'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
    Framework.getStoreRegistry().getStore('effectivenessData').resetStoreValues();
  }
}
