import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const addTestSuite = types.model('addTestSuite', {
  testSuiteName: types.maybeNull(types.string),
  testFileName: types.maybeNull(types.string),
  testId: types.maybeNull(types.string)
});

const AddLLMTestSuite = types
  .model('AddLLMTestSuite', {
    status: types.maybeNull(types.string),
    data: types.optional(types.array(addTestSuite), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_TEST_SUITE_DATA, {
        payload
      });
    },
    save(payload) {
      Framework.getEventManager().publish(constants.ADD_TEST_SUITE_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.SET_TEST_SUITE_DATA);
    }
  }));

export default AddLLMTestSuite;
