import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import utils from '../../../../common/utils';

const MathJaxRender = ({ content }) => {
  const [mathJaxContent, setMathJaxContent] = useState('');

  useEffect(() => {
    const fetchFinalData = async () => {
      let response = content;
      if (!_.isEmpty(response)) {
        if (utils.containsMarkDownText(response)) {
          const data = await utils.textFromContentV3(response);
          response = await utils.convertToMarkedDownStringV3(data);
        }
        if (response.includes('`') || response.includes('</math>') || response.includes('\\')) {
          const regesXmlns = /<math([^>]*)>/g;
          const res = utils.containsMathML(response)
            ? response.replace(regesXmlns, '<math xmlns="http://www.w3.org/1998/Math/MathML">')
            : response;
          setMathJaxContent(
            <>
              {/* eslint-disable */}
              <span dangerouslySetInnerHTML={{ __html: res }} />
              {/* eslint-disable */}
            </>
          );
        }
      }
      setMathJaxContent(
        <>
          {/* eslint-disable-next-line */}
          <span dangerouslySetInnerHTML={{ __html: response }} />
        </>
      );
    };
    fetchFinalData();
  }, [content]);
  return mathJaxContent;
};

export default MathJaxRender;
