import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
// import { toJS } from 'mobx';
import * as constants from '../../../common/constants';

const feedbacks = types.model('feedbacks', {
  'Answer Relevance': types.maybeNull(types.number),
  reason: types.maybeNull(types.string),
  'Context Relevance': types.maybeNull(types.number),
  Groundedness: types.maybeNull(types.number),
  Correctness: types.maybeNull(types.number),
  statement: types.maybeNull(types.string),
  reasons: types.maybeNull(types.string),
  gpt_groundedness: types.maybeNull(types.number),
  gpt_relevance: types.maybeNull(types.number),
  gpt_coherence: types.maybeNull(types.number),
  gpt_fluency: types.maybeNull(types.number),
  gpt_similarity: types.maybeNull(types.number),
  f1_score: types.maybeNull(types.number)
});

const metadatas = types.model('metadata', {
  executionId: types.maybeNull(types.string),
  dateExecuted: types.maybeNull(types.string),
  executedBy: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
  s3FilePath: types.maybeNull(types.string),
  testName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  testId: types.maybeNull(types.string)
});

const record = types.model('metadata', {
  input: types.maybeNull(types.string),
  output: types.maybeNull(types.string),
  prompt: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  feedbacks: types.optional(types.array(feedbacks), [])
});

const effectiveness = types.model('effectiveness', {
  scores: types.optional(types.array(record), []),
  metadata: types.optional(types.maybeNull(metadatas))
});

const EffectivenessData = types
  .model('EffectivenessData', {
    data: types.optional(types.array(effectiveness), []),
    fileName: types.maybeNull(types.string)
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_STUDYTOOL_EFFECTIVENESS_DATA, {
        payload
      });
    },
    fetchMetadata(payload) {
      Framework.getEventManager().publish(constants.GET_STUDYTOOL_EFFECTIVENESS_METADATA, { payload });
    },
    setMetaDataResponse(response) {
      const { data } = response;
      applySnapshot(self, { data });
      Framework.getEventManager().publish(constants.SET_STUDYTOOL_EFFECTIVENESS_METADATA);
    },
    initiate(payload) {
      Framework.getEventManager().publish(constants.EFFECTIVENESS_INITIATE_DATA, { payload });
    },
    initiateFileUpload(payload) {
      Framework.getEventManager().publish(constants.EFFECTIVENESS_TRIGGER_FILE_UPLOAD, { payload });
    },
    fetchLLMResults(payload) {
      Framework.getEventManager().publish(constants.GET_LLM_TEST_RESULTS_DATA, {
        payload
      });
    },
    setResponse(response) {
      const { data } = response;
      applySnapshot(self, { data });
      Framework.getEventManager().publish(constants.SET_STUDYTOOL_EFFECTIVENESS_DATA);
    },
    /* eslint-disable */
    setFileUploadResponse(response) {
      const { data } = response;
      self.fileName = data.fileName;
      Framework.getEventManager().publish(constants.EFFECTIVENESS_SET_FILE_UPLOAD_RESPONSE);
    },
    setFileEmpty() {
      self.fileName = '';
      Framework.getEventManager().publish(constants.EFFECTIVENESS_SET_FILE_UPLOAD_RESPONSE);
    },
    /* eslint-enable */
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_STUDYTOOL_EFFECTIVENESS_DATA);
    }
  }));

export default EffectivenessData;
