import Framework, { shapes } from '@greenville/framework';
import { Box, Paper } from '@material-ui/core';
import TablePagination from '@mui/material/TablePagination';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import TableComponent from '../../../../../common/components/Table';
import ColumnConfig from '../../../../../common/config/ColumnConfig';
import * as constants from '../../../../../common/constants';
import ExportToCSV from '../../../common/components/ExportToCSV';
import AIContentFilterChartsModel from '../../../models/AIContentFilterCharts';
import AIContentFilterChartsDetailsModel from '../../../models/AIContentFilterChartsDetails';
import ChatgptUtilityIESUser from '../../../models/ChatgptIESUser';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AIContentFilterCharts = (props) => {
  const {
    aiContentfilterCharts,
    aiContentfilterChartsDetails,
    searchFields,
    ChatgptUtilityIESUserData,
    handleOnSearch,
    isSearchClicked,
    bookList,
    isUserIdExcluded
  } = props;
  const [chartsData, setChartsData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setSelectedtableColumns] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');

  const handleReset = () => {
    setTableData([]);
    setChartsData({});
  };

  const isSearchCompleted = (param) => {
    handleOnSearch(param);
  };

  useEffect(() => {
    if (isSearchClicked) {
      const getChartsData = async () => {
        const payload = {
          ...(searchFields?.bookId && searchFields?.bookId !== constants.ALL_BOOKS && { bookId: searchFields?.bookId }),
          ...(searchFields?.startDate && { startDate: searchFields.startDate }),
          ...(searchFields?.endDate && { endDate: searchFields.endDate }),
          isUserIdExcluded
        };
        aiContentfilterCharts.fetch(payload);
        Framework.getEventManager().on(constants.SET_AI_CONTENT_FILTER_CHARTS_UTILITY_DATA, () => {
          const { data } = aiContentfilterCharts;
          const resp = toJS(data)[0];
          if (resp) {
            // Prepare chart data
            const chartResData = {
              labels: ['SelfHarm', 'Hate', 'Violence', 'Sexual'],
              datasets: [
                {
                  label: 'Severity Count',
                  data: [resp.selfharmSeverity, resp.hateSeverity, resp.violenceSeverity, resp.sexualSeverity],
                  backgroundColor: 'rgba(75, 192, 192, 0.2)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1
                }
              ]
            };
            setChartsData(chartResData);
          } else {
            handleReset();
          }
        });
        /* eslint-disable camelcase */
        Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
          const { userData } = ChatgptUtilityIESUserData;
          if (userData.length > 0) {
            setUserEmailDetails(userData[userData.length - 1]);
          }
        });
        isSearchCompleted(false);
      };

      getChartsData();
    }
  }, [isSearchClicked, isUserIdExcluded]);

  // Determine table columns based on selected category
  const getTableColumns = (category) => {
    switch (category) {
      case 'Violence':
        return ColumnConfig.AIContentFilterChartsViolenceColumn;
      case 'Hate':
        return ColumnConfig.AIContentFilterChartsHateColumn;
      case 'SelfHarm':
        return ColumnConfig.AIContentFilterChartsSelfHarmColumn;
      case 'Sexual':
        return ColumnConfig.AIContentFilterChartsSexualColumn;
      default:
        return [];
    }
  };

  const handleBarClick = (event, elements) => {
    if (elements.length > 0) {
      const indexVal = elements[0].index; // Get the index of the clicked bar
      const label = chartsData.labels[indexVal]; // Get the corresponding label
      const payload = {
        ...(searchFields?.bookId && searchFields?.bookId !== constants.ALL_BOOKS && { bookId: searchFields?.bookId }),
        ...(searchFields?.startDate && { startDate: searchFields.startDate }),
        ...(searchFields?.endDate && { endDate: searchFields.endDate }),
        category: label,
        isUserIdExcluded
      };
      aiContentfilterChartsDetails.fetch(payload);
      Framework.getEventManager().on(constants.SET_AI_CONTENT_FILTER_CHARTS_DETAILS_DATA, () => {
        const { data } = aiContentfilterChartsDetails;
        const resp = toJS(data);
        if (resp) {
          const tableColumnData = getTableColumns(label);
          setSelectedtableColumns(tableColumnData);
          bookList.forEach((value) => {
            const bookIdVal = value.bookId;
            if (!bookIdVal) return;
            resp.forEach((val, index) => {
              if (val.bookId === bookIdVal) {
                resp[index].title = value.title;
              }
            });
          });
          setTableData(resp);
          setPage(0);
        }
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFromToValueForPagination = (from, to, count) => {
    return `${from}-${to} of ${count}`;
  };

  const handleAscending = (dir, value) => {
    if (dir === 'asc') {
      const sorted = [...tableData].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      setTableData(sorted);
    } else {
      const sorted = [...tableData].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      setTableData(sorted);
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const onColumnClick = (val) => {
    sorting(val);
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw || 0;
            return `${label}: ${value}`;
          }
        }
      },
      title: {
        display: true,
        text: 'Severity Counts by Category'
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    interaction: {
      mode: 'nearest',
      intersect: true
    },
    elements: {
      bar: {
        hoverBorderWidth: 2
      }
    },
    onClick: (event, elements) => handleBarClick(event, elements),
    scales: {
      x: {
        title: {
          display: true,
          text: 'Categories'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Severity Count'
        },
        beginAtZero: true
      }
    }
  };

  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((item) => item.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const onMouseEnter = (val) => {
    hanldeGETUserId(val);
  };

  return (
    <>
      {!_.isEmpty(chartsData) && (
        <Paper style={{ padding: '10px', marginBottom: '10px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer'
              }}
            >
              <h2>Blocked Content - Saftey Trend Chart</h2>
              <Bar data={chartsData} options={chartOptions} />
            </div>
          </div>
        </Paper>
      )}
      {!_.isEmpty(tableData) && tableData.length > 0 && (
        <>
          <ExportToCSV headers={tableColumns} data={tableData} fileTitle="AIContentFilter" />
          <Paper
            style={{
              maxHeight: '500px',
              overflow: 'hidden',
              overflowY: 'scroll',
              borderRadius: '2px',
              border: '1px solid #0003',
              padding: '10px'
            }}
          >
            <Box sx={{ width: '100%' }} style={{ marginTop: '30px' }}>
              <TableComponent
                columns={tableColumns}
                data={tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                onColumnClick={onColumnClick}
                order={order}
                onRowClick={() => {}}
                directionValue={directionValue}
                userEmailDetails={userEmailDetails}
                onMouseEnter={onMouseEnter}
                showSelectColumn
                isFromCharts
              />
              <TablePagination
                component="div"
                count={tableData.length}
                labelDisplayedRows={({ from, to, count }) =>
                  useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};

AIContentFilterCharts.propTypes = {
  aiContentfilterCharts: shapes.modelOf(AIContentFilterChartsModel).isRequired,
  aiContentfilterChartsDetails: shapes.modelOf(AIContentFilterChartsDetailsModel).isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  searchFields: PropTypes.object.isRequired,
  handleOnSearch: PropTypes.func.isRequired,
  isSearchClicked: PropTypes.bool.isRequired,
  bookList: PropTypes.array.isRequired,
  isUserIdExcluded: PropTypes.bool.isRequired
};

export default inject(
  'aiContentfilterCharts',
  'aiContentfilterChartsDetails',
  'ChatgptUtilityIESUserData'
)(observer(AIContentFilterCharts));
