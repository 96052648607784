/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import Framework, { shapes } from '@greenville/framework';
import { VegaReader } from '@vega/vega-reader';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import interact from 'interactjs';
import { ResourceContext } from '@vega/resources';
import env from '../../../common/env';
import VegaConfig from '../config/VegaConfig';
import AuthConfig from '../config/AuthConfig';
import Asset from '../model/Asset';
import EpubAsset from '../model/EpubAsset';
import Hotspot from '../model/Hotspot';
import utils from '../../../common/utils';
import * as constants from '../../../common/constants';
import DialogConfig from '../../../common/config/DialogConfig';
import HotspotDialog from './HotspotDialog';
import IconHotspotMenu from './menu/IconHotspotMenu';
import PageHotspotMenu from './menu/PageHotspotMenu';
import ToggleMenu from './menu/ToggleMenu';
import HotspotPreview from './HotspotPreview';
import MediaBasepaths from '../../books/models/MediaBasepaths';
import InterfaceColors from '../../books/models/InterfaceModel';
import UsersPermissions from '../../users/models/UsersPermissions';
import HotspotUtils from '../common/HotspotUtils';
import Loader from '../../../common/Loader';
import ResourcePanel from '../model/Resources';
import Books from '../../books/models/Books';
import UnSupportedPreview from './UnSupportedPreview';
// import EpubTocData from '../common/data/EpubTocData';
// import EpubPlaylistData from '../common/data/EpubPlaylistData.json';

const styles = () => ({
  disableIcons: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
});

@inject(
  'asset',
  'hotspot',
  'epubAsset',
  'saveHotspotStatus',
  'colorLanguageStatus',
  'deleteHotspotStatus',
  'basepath',
  'colorInterface',
  'userPermissions',
  'resources'
)
@observer
class PdfViewer extends PureComponent {
  static propTypes = {
    asset: shapes.modelOf(Asset).isRequired,
    hotspot: shapes.modelOf(Hotspot).isRequired,
    epubAsset: shapes.modelOf(EpubAsset).isRequired,
    match: PropTypes.object.isRequired,
    saveHotspotStatus: shapes.state.isRequired,
    deleteHotspotStatus: shapes.state.isRequired,
    basepath: shapes.modelOf(MediaBasepaths).isRequired,
    colorInterface: shapes.modelOf(InterfaceColors).isRequired,
    colorLanguageStatus: shapes.state.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired,
    resourcePanel: shapes.modelOf(ResourcePanel).isRequired,
    booksState: shapes.modelOf(Books).isRequired
  };

  static childContextTypes = {
    preferenceContext: PropTypes.object,
    studyContext: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      deleteHotspot: false,
      disabledArr: [],
      hotspotPreviewToggle: false,
      hotspotPositionToggle: false,
      requiredArr: [],
      showHotspotModal: false,
      showHotspotPreview: false,
      updateHotspotData: {
        hotspotData: [],
        bookFeatures: {}
      },
      drawerView: {
        drawerState: constants.DRAWER_STATE_CLOSED,
        selectedPanel: ''
      },
      currentPageIdArray: [],
      resources: [],
      isUnsupportedHotspot: false
    };
    this.pageId = '';
    this.closeHotspotModal = this.closeHotspotModal.bind(this);
    this.saveHotspot = this.saveHotspot.bind(this);
    this.deleteHotspot = this.deleteHotspot.bind(this);
    this.setHotSpotData = this.setHotSpotData.bind(this);
    this.initialize();
  }

  getChildContext() {
    return {
      preferenceContext: {
        showColorMode: false,
        showPagelayout: this.contentType === constants.BOOK_CONTENT_TYPE.EPUB,
        showPageProgression: false,
        showAnnotationSwitch: false,
        showFontOptions: false,
        showTextTransform: false,
        showLineSpacing: false,
        showHotSpots: this.contentType === constants.BOOK_CONTENT_TYPE.PDF
      },
      studyContext: {
        glossaryLanguage: false,
        showCustomDecks: false,
        showExpertDecks: false,
        showKeyTerms: false
      }
    };
  }

  componentDidMount() {
    const { match, hotspot, resourcePanel, asset } = this.props;
    const { bookid, pageid } = match.params;
    this.pageId = pageid;
    this.setState({
      disabledArr: []
    });
    if (pageid !== undefined) {
      hotspot.fetch(bookid, pageid);
    }
    if (asset && asset.globalBookId && asset.globalBookId != null) {
      const { globalBookId } = asset;
      if (globalBookId !== null) {
        resourcePanel.fetch(globalBookId);
        Framework.getEventManager().on(constants.RESOURCE_FETCHED, (data) => {
          this.setState({
            resources: data
          });
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.setPageIdState(prevProps);
  }

  componentWillUnmount() {
    this.repositionElements.forEach((selector) => {
      interact(selector).unset();
    });
  }

  setPageIdState(prevProps) {
    const { match, hotspot } = this.props;
    if (prevProps.match.params.pageid === undefined && match.params.pageid !== prevProps.match.params.pageid) {
      this.pageId = match.params.pageid;
    }
    if (match.params.pageid !== prevProps.match.params.pageid) {
      hotspot.fetch(match.params.bookid, match.params.pageid);
    }
  }

  setHotSpotData(hotspotObj = {}, forceFetch = true) {
    const { match, hotspot } = this.props;
    const { bookid, pageid } = match.params;
    const hotspotSubscription = Framework.getEventManager().on(constants.PDF_HOTSPOT_FETCHED, () => {
      const hotspotData = hotspot.getHotspotRegionList(bookid, pageid, hotspotObj);
      this.setState({ updateHotspotData: { hotspotData }, deleteHotspot: false }, () => {
        // When reposition toggle is on, reattach the interactJS events
        const { hotspotPositionToggle } = this.state;
        if (hotspotPositionToggle) {
          this.setRepositionElementStyle(hotspotPositionToggle);
        }
      });
      hotspotSubscription();
    });

    if (forceFetch) {
      hotspot.fetch(bookid, pageid);
    }
  }

  initialize = () => {
    const auth = new AuthConfig();
    const { getAuthConfig } = auth;
    const vegaConfig = new VegaConfig();
    const { getVegaConfig, getRpluseConfig } = vegaConfig;

    this.vegaConfig = getVegaConfig();
    this.rPlusConfig = getRpluseConfig();
    this.authHandler = getAuthConfig();
    this.repositionElements = ['.hotspotIcon'];
    this.createEditRegion = {};
    this.hotspotToolType = null;
    this.currentPageIndex = '';
    this.viewerInstance = null;

    // callback object for Pdf books
    this.pdfCallbacks = {
      hotspotObj: {
        bookFeatures: {},
        fetchClickedRegionData: (editRegionID) => {
          const { hotspotPreviewToggle, hotspotPositionToggle } = this.state;
          if (!hotspotPositionToggle) {
            const { hotspot } = this.props;
            this.createEditRegion = hotspot.getEditHotspotData(editRegionID);
            if (hotspotPreviewToggle) {
              this.setState({ showHotspotPreview: true });
            } else {
              this.disabledRequiredRegionProps(this.createEditRegion.linkTypeId);
              this.setState({ showHotspotModal: true });
            }
          }
        },

        fetchHotspotData: (pageId) =>
          new Promise((resolve) => {
            const { asset, hotspot, match } = this.props;
            const { bookid, pageid } = match.params;
            const pagePlaylist = asset.slates;
            const pageData = pagePlaylist.find((item) => item.playOrder === pageId);
            const pageOrder = pageData && pageData.playOrder;
            this.onPdfPageTextLoad(pageData.pagenumber, asset.globalBookId);
            const hotspotSubscription = Framework.getEventManager().on(constants.PDF_HOTSPOT_FETCHED, () => {
              resolve();
              const { hotspotPositionToggle } = this.state;
              const hotspotData = hotspot.getHotspotRegionList(bookid, pageid);
              this.setState({ updateHotspotData: { hotspotData } });
              if (hotspotPositionToggle) {
                this.setRepositionElementStyle(hotspotPositionToggle);
              }
              if (hotspotData) {
                this.forceRefresh();
              }
              hotspotSubscription();
            });
            hotspot.fetch(bookid, pageOrder);
          }),
        renderGlossaryData: () => {}
      }
    };
    // EPUB related adjustments
    // const { isDoublePage, isFixedLayout, isRightToLeft, layoutDimensions, preferenceObject, printPageNumber, tocData } =
    //   EpubTocData;

    // this.isDoublePage = isDoublePage;
    // this.isFixedLayout = isFixedLayout;
    // this.isRightToLeft = isRightToLeft;
    // this.layoutDimensions = layoutDimensions;
    // this.preferenceObject = preferenceObject;
    // this.printPageNumber = printPageNumber;
    // this.tocDataEpub = tocData;
    // this.pagePlaylistEpub = EpubPlaylistData;
    this.contentType = '';
  };

  saveHotspot = (data, removeDuplicate = false) => {
    const { match, hotspot } = this.props;
    const { hotspotPositionToggle } = this.state;
    const { bookid } = match.params;
    hotspot.saveHotspotData(bookid, data, hotspotPositionToggle, removeDuplicate);
    this.closeHotspotModal(false);
  };

  forceRefresh = () => {
    const { hotspot } = this.props;
    const data = hotspot.forceRefreshData;
    this.saveHotspot(data, true);
  };

  deleteHotspot = (data) => {
    const { match, hotspot } = this.props;
    const { bookid } = match.params;
    hotspot.deleteHotspotData(bookid, data);
    this.closeHotspotModal(false);
    this.setState({ deleteHotspot: true });
  };

  closeHotspotModal = (forceFetch) => {
    this.hotspotToolType = null;
    this.setHotSpotData({}, forceFetch);
    this.setState({ showHotspotModal: false });
  };

  closePreviewModal = () => {
    this.setState({ showHotspotPreview: false });
  };

  disabledRequiredRegionProps = (linkTypeID) => {
    let disabled = [];
    let required = [];
    DialogConfig.linkTypeDropDown.forEach((item) => {
      if (item.id === Number(linkTypeID)) {
        disabled = item.disabled;
        required = item.required;
      }
    });
    this.setState({ disabledArr: disabled, requiredArr: required });
  };

  appLoaded = () => {};

  onPdfPageTextLoad = (pageNumber, globalBookId) => {
    if (this.viewerInstance === null && window.WebPDF) {
      this.viewerInstance = window.WebPDF.ViewerInstance;
      this.currentPageIndex = (this.viewerInstance && this.viewerInstance.getCurPageIndex()) || 0;
    }
    const thisScope = this;
    let hotspotRect = {};
    let hotspotProps = {};
    let canvasRegion = HotspotUtils.createCanvasElPage(this.currentPageIndex);
    const ctx = canvasRegion.getContext('2d');
    let drag = false;

    function mouseDown(e) {
      if (thisScope.hotspotToolType !== null) {
        canvasRegion = HotspotUtils.createCanvasElPage(thisScope.currentPageIndex);
        const { widthScale, heightScale } = HotspotUtils.getPageScale(thisScope.currentPageIndex);
        const x = e.offsetX / widthScale;
        const y = e.offsetY / heightScale;
        hotspotRect.x = Math.round(x);
        hotspotRect.y = Math.round(y);
        if (thisScope.hotspotToolType === constants.PAGE_HOTSPOT) {
          drag = true;
          hotspotProps.linkTypeId = 0;
          hotspotProps.regionTypeId = 0;
          hotspotProps.iconTypeId = 1;
        } else if (thisScope.hotspotToolType === constants.ICON_HOTSPOT) {
          hotspotProps.linkTypeId = thisScope.createHotspotProps.linkTypeId;
          hotspotProps.regionTypeId = thisScope.createHotspotProps.regionTypeId;
          hotspotProps.iconTypeId = thisScope.createHotspotProps.iconTypeId;
          hotspotProps.platformId = thisScope.createHotspotProps.platformId;
          hotspotRect.h = 34;
          hotspotRect.w = 34;
        }
        hotspotProps.page = pageNumber;
        hotspotProps.globalBookId = globalBookId;
      }
    }

    function mouseUp() {
      if (thisScope.hotspotToolType !== null) {
        drag = false;
        if (hotspotRect && hotspotRect.h && hotspotRect.w) {
          canvasRegion.style.cursor = 'default';
          thisScope.createEditRegion = HotspotUtils.initialCreateHotspotData(hotspotRect, hotspotProps);
          thisScope.disabledRequiredRegionProps(thisScope.createEditRegion.linkTypeId);
          thisScope.setState({
            showHotspotModal: true
          });
        }
        ctx.clearRect(0, 0, canvasRegion.width, canvasRegion.height);
        hotspotRect = {};
        hotspotProps = {};
      }
    }

    function mouseMove(e) {
      if (drag) {
        let { hotSpotColor, regionHotSpotAlpha } = constants.HOTSPOT_DEFAULT_FEATURE_CONFIG;
        if (!_.isEmpty(thisScope.pdfCallbacks.hotspotObj.bookFeatures)) {
          hotSpotColor = thisScope.pdfCallbacks.hotspotObj.bookFeatures.hotSpotColor;
          regionHotSpotAlpha = thisScope.pdfCallbacks.hotspotObj.bookFeatures.regionHotSpotAlpha;
        }

        const { widthScale, heightScale } = HotspotUtils.getPageScale(thisScope.currentPageIndex);
        const w = e.offsetX / widthScale - hotspotRect.x;
        const h = e.offsetY / heightScale - hotspotRect.y;
        hotspotRect.w = Math.round(w);
        hotspotRect.h = Math.round(h);
        ctx.clearRect(0, 0, canvasRegion.width, canvasRegion.height);
        ctx.fillStyle = hotSpotColor;
        ctx.globalAlpha = regionHotSpotAlpha / 100;
        ctx.fillRect(
          hotspotRect.x * widthScale,
          hotspotRect.y * heightScale,
          hotspotRect.w * widthScale,
          hotspotRect.h * heightScale
        );
      }
    }

    function init() {
      canvasRegion.addEventListener('mousedown', mouseDown, false);
      canvasRegion.addEventListener('mouseup', mouseUp, false);
      canvasRegion.addEventListener('mousemove', mouseMove, false);
    }

    init();
  };

  renderBasketLevelRegions = (hotspot) => {
    const hotspotData = {};
    hotspotData.name = hotspot.title;
    hotspotData.linkTypeId = hotspot.linkTypeId;
    hotspotData.regionTypeId = hotspot.regionTypeId;
    hotspotData.linkUri = hotspot.linkUri;
    this.createEditRegion = hotspotData;
    this.setState({ showHotspotPreview: true });
  };

  handleBackNavigationClick = () => {
    const { match } = this.props;
    Framework.redirectTo(`/books/${match.params.bookid}`);
  };

  handleNavigationChange = (navPageId, isExternal) => {
    const { hotspotPreviewToggle } = this.state;
    const { asset, match, epubAsset } = this.props;
    const pagePlaylist = asset.slates;
    const previousPageId = this.pageId;
    const customAsset = [constants.ASSET, constants.STANDARDS, constants.ASSESSMENT, constants.CUSTOM];
    const regionAsset = [constants.ASSET, constants.STANDARDS, constants.ASSESSMENT];
    const tocAsset = [constants.MODULE, constants.SUBMODULE];
    let currentPageId = navPageId;
    if (regionAsset.includes(navPageId.type) && navPageId.linkTypeId !== '' && navPageId.regionTypeId !== '') {
      if (hotspotPreviewToggle) {
        this.renderBasketLevelRegions(navPageId);
      }
    }

    if (isExternal) {
      const tocList = epubAsset.tocData.children;
      const tocDataList = this.generateTocDataList(tocList);
      const matchObj = tocDataList.find((value) => value.id === navPageId);
      if (matchObj) {
        const linkUrl = matchObj.href;
        window.open(linkUrl, '_blank');
      }
    }

    if (navPageId && navPageId.type) {
      if (customAsset.includes(navPageId.type)) {
        currentPageId = previousPageId;
      } else if (tocAsset.includes(navPageId.type)) {
        currentPageId = navPageId.id;
      } else if (navPageId.id) {
        const pageObj = pagePlaylist.find((item) => item.id === navPageId.id);
        if (pageObj && pageObj.id) {
          currentPageId = pageObj.id;
        }
      }
    }
    this.pageId = currentPageId;

    if (currentPageId !== previousPageId) {
      Framework.redirectTo(`/books/${match.params.bookid}/preview/${currentPageId}`);
    }
  };

  generateTocDataList = (tocList) => {
    const tocDataList = [];
    const drillToDeep = (node) => {
      tocDataList.push(node);
      const { children } = node;
      if (children && children.length > 0) {
        children.forEach((child) => {
          drillToDeep(child);
        });
      }
    };
    if (tocList && tocList.length > 0) {
      tocList.forEach((node) => {
        drillToDeep(node);
      });
    }
    return tocDataList;
  };

  setHotspotToolType = (type, createHotspotProps = {}) => {
    this.hotspotToolType = type;
    this.createHotspotProps = createHotspotProps;
    const canvasRegion = HotspotUtils.createCanvasElPage(this.currentPageIndex);
    canvasRegion.style.cursor = 'crosshair';
  };

  setBackLinkClient = () => {
    this.backLinkClient = {
      launchParams: {
        backLinkQueryparams: {},
        backLinkLaunchParams: {}
      },
      endPoint: ''
    };
  };

  setHotspotPreviewToggle = (toggleValue) => {
    this.setState({ hotspotPreviewToggle: toggleValue });
  };

  setHotspotPositionToggle = (toggleValue) => {
    this.setState({ hotspotPositionToggle: toggleValue });
    // Attach/detach the interactjs events
    this.setRepositionElementStyle(toggleValue);
  };

  setRepositionElementStyle = (toggleValue) => {
    const hotspotIconEl = document.getElementsByClassName('hotspotIcon');
    const hotspotEl = document.getElementsByClassName('hotspot');
    if (toggleValue) {
      this.repositionElements.forEach((selector) => {
        this.repositionHotspot(selector);
      });

      // Temporarily increasing zIndex of hotspotIcon when reposition toggle is ON
      if (hotspotIconEl && hotspotIconEl.length > 0) {
        for (let i = 0; i < hotspotIconEl.length; i += 1) {
          hotspotIconEl[i].style.zIndex = 3;
        }
      }

      if (hotspotEl && hotspotEl.length > 0) {
        for (let i = 0; i < hotspotEl.length; i += 1) {
          hotspotEl[i].style.cursor = 'not-allowed';
        }
      }
    } else {
      this.repositionElements.forEach((selector) => {
        interact(selector).draggable(false);
      });

      // Resetting zIndex when reposition toggle is OFF
      if (hotspotIconEl && hotspotIconEl.length > 0) {
        for (let i = 0; i < hotspotIconEl.length; i += 1) {
          hotspotIconEl[i].style.zIndex = '';
        }
      }

      if (hotspotEl && hotspotEl.length > 0) {
        for (let i = 0; i < hotspotEl.length; i += 1) {
          hotspotEl[i].style.cursor = 'pointer';
        }
      }
    }
  };

  repositionHotspot = (source) => {
    const thisScope = this;
    interact(source).draggable({
      inertia: true,
      origin: 'parent',
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true
        })
      ],
      listeners: {
        move(event) {
          const { target } = event;
          // keep the dragged position in the data-x/data-y attributes
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
          // translate the element
          target.style.transform = `translate(${x}px, ${y}px)`;
          // update the posiion attributes
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        },
        end(event) {
          const { pageX, pageY } = event;
          const { hotspot } = thisScope.props;
          const { widthScale, heightScale } = HotspotUtils.getPageScale(thisScope.currentPageIndex);
          const offset = 22;
          const currX = Math.round((pageX - offset) / widthScale);
          const currY = Math.round((pageY - offset) / heightScale);
          // Get the region id from ID attribute
          const editRegionPayload = hotspot.getEditHotspotData(event.currentTarget.id);
          editRegionPayload.x = currX < 0 ? 0 : currX;
          editRegionPayload.y = currY < 0 ? 0 : currY;
          thisScope.saveHotspot(editRegionPayload);
        }
      }
    });
  };

  menuIconsStart = () => {
    const { hotspotPreviewToggle, hotspotPositionToggle } = this.state;

    const disableMenus = hotspotPreviewToggle || hotspotPositionToggle;
    return (
      <Box display="flex">
        <PageHotspotMenu handleClick={this.setHotspotToolType} disableMenu={disableMenus} />
        <IconHotspotMenu handleClick={this.setHotspotToolType} disableMenu={disableMenus} />
      </Box>
    );
  };

  menuIconsEnd = () => {
    const { hotspotPreviewToggle, hotspotPositionToggle } = this.state;
    const { userPermissions } = this.props;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    const canEdit = permissionsList && permissionsList.includes('authoring_can_edit');
    const positionTooltipText = canEdit ? 'tooltip.POSITION_HOTSPOT_ON_OFF' : 'user.PERMISSIONS_LABEL';

    return (
      <Box display="flex">
        <ToggleMenu
          handleChange={this.setHotspotPositionToggle}
          disableMenu={!canEdit || hotspotPreviewToggle}
          languageProps={{
            labelText: 'label.POSITION_HOTSPOT_TOGGLE',
            tooltipText: positionTooltipText
          }}
        />

        <ToggleMenu
          handleChange={this.setHotspotPreviewToggle}
          disableMenu={hotspotPositionToggle}
          languageProps={{
            labelText: 'label.PREVIEW_HOTSPOT_TOGGLE',
            tooltipText: 'tooltip.PREVIEW_HOTSPOT_ON_OFF'
          }}
        />
      </Box>
    );
  };

  getCurrentPage = (currentPageNumber) => {
    const { epubAsset, asset } = this.props;
    let pagePlaylistData;
    if (
      epubAsset &&
      (epubAsset.contentType === constants.BOOK_CONTENT_TYPE.EPUB || epubAsset.contentType === 'RPLUS_EPUB')
    ) {
      pagePlaylistData = toJS(epubAsset.slates);
    } else {
      pagePlaylistData = toJS(asset.slates);
    }
    const pageNumber = parseInt(currentPageNumber, 10);
    let currentPage1 = {};
    if (pagePlaylistData && pagePlaylistData.length > 0) {
      const currentPageIndex = pagePlaylistData.findIndex((eachPage) => eachPage.pagenumber === pageNumber.toString());
      currentPage1 = pagePlaylistData[currentPageIndex];
      if (!currentPage1) {
        const [currentPage] = pagePlaylistData;
        currentPage1 = currentPage;
      }
    }
    return currentPage1;
  };

  handleOnDrawerExpand = () => {
    const { drawerView } = this.state;
    const { drawerState, selectedPanel } = drawerView;
    if (drawerState === constants.DRAWER_STATE_EXPANDED) {
      this.setState({ drawerView: { drawerState: constants.DRAWER_STATE_OPEN } });
      // eslint-disable-next-line
      selectedPanel = constants.DRAWER_PANEL_RESOURCES;
    }
  };

  closeUnsupportedPreviewDialog = () => {
    this.setState({ isUnsupportedHotspot: false });
  };

  handleResourceCallback = (resourceData) => {
    if (resourceData) {
      const currentPage = this.getCurrentPage(resourceData.pageId);
      if (resourceData.fileType === constants.AUDIO_FILE_TYPE) {
        this.setState({ isUnsupportedHotspot: true });
      } else if (resourceData.contentTypeValue === constants.CONTENT_TYPE_INTERNAL_LINK) {
        const currentPageId = [];
        currentPageId.push(resourceData.pageId);
        this.handleNavigationChange(currentPage.id);
        this.setState({ currentPageIdArray: currentPageId });
        this.handleOnDrawerExpand();
      } else {
        window.open(`${resourceData.url}`, '_blank');
      }
    }
  };

  handleDrawerChange = (drawer) => {
    this.selectedPanel = '';
    // eslint-disable-next-line
    this.setState({ drawerState: drawer });
  };

  isReadyToRender() {
    let isReadyToRender = false;
    const { asset, epubAsset, match, colorLanguageStatus } = this.props;
    const { bookid, pageid } = match.params;
    if (this.pageId === pageid && asset && asset.bookId === bookid && !colorLanguageStatus.isPending) {
      isReadyToRender = true;
    } else if (
      epubAsset &&
      epubAsset.bookId === bookid &&
      (epubAsset.contentType === constants.BOOK_CONTENT_TYPE.EPUB || epubAsset.contentType === 'RPLUS_EPUB')
    ) {
      isReadyToRender = true;
    }
    return isReadyToRender;
  }

  render() {
    const isReady = this.isReadyToRender();
    const { currentPageIdArray, resources, isUnsupportedHotspot } = this.state;
    const { asset, epubAsset, match, saveHotspotStatus, deleteHotspotStatus, booksState } = this.props;
    const { basepath, colorLanguageStatus, colorInterface } = this.props;
    const { titleType } = booksState.bookDetail;
    const { pageid, bookid } = match.params;
    const {
      deleteHotspot,
      showHotspotModal,
      disabledArr,
      requiredArr,
      showHotspotPreview,
      updateHotspotData,
      drawerView
    } = this.state;

    let pagePlaylistData;
    let tocData;

    let resourceCount = false;
    if (resources && resources.length > 0) {
      resourceCount = true;
    }

    if (
      epubAsset &&
      (epubAsset.contentType === constants.BOOK_CONTENT_TYPE.EPUB || epubAsset.contentType === 'RPLUS_EPUB')
    ) {
      pagePlaylistData = isReady ? toJS(epubAsset.slates) : [];
      tocData = isReady ? toJS(epubAsset.tocData) : {};
      this.contentType = constants.BOOK_CONTENT_TYPE.EPUB;
    } else {
      pagePlaylistData = isReady ? toJS(asset.slates) : [];
      tocData = isReady ? toJS(asset.children) : {};
      this.contentType = this.vegaConfig.bookFormat;
    }

    if (this.contentType === constants.BOOK_CONTENT_TYPE.PDF && pageid === undefined && asset.bookId) {
      const firstPageId = asset && asset.slates.length && asset.slates[0].playOrder;
      Framework.redirectTo(`/books/${bookid}/preview/${firstPageId}`);
    }

    if (!colorLanguageStatus.isPending && _.isEmpty(this.pdfCallbacks.hotspotObj.bookFeatures)) {
      this.pdfCallbacks.hotspotObj.bookFeatures = colorInterface.getHotspotFeaturesConfig(bookid);
    }

    return (
      <>
        {isReady && !deleteHotspot && (
          <ResourceContext
            currentPageId={currentPageIdArray}
            // env={env.ENVIRONMENT}
            userRole="Teacher"
            onResourceClick={this.handleResourceCallback}
            locale="en"
            resourceData={resources}
          >
            <VegaReader
              appLoaded={this.appLoaded}
              authHandler={this.authHandler}
              backLinkClient={this.backLinkClient}
              basePath={
                this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                  ? epubAsset.basePath
                  : asset.basePath
              }
              bookId={
                this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                  ? epubAsset.bookId
                  : asset.bookId
              }
              contentType={this.contentType}
              courseId={
                this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                  ? epubAsset.bookId
                  : asset.bookId
              }
              indexId={
                this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                  ? epubAsset.indexId
                  : asset.globalBookId
              }
              bookInfo={{
                author:
                  this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                    ? epubAsset.author
                    : utils.authorFullName(asset.authorList),
                thumbnailUrl:
                  this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                    ? epubAsset.thumbnailUrl
                    : asset.thumbnailArt,
                title:
                  this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                    ? epubAsset.title
                    : asset.children.title,
                // EPUB related adjustments
                isDoublePage: epubAsset.isDoublePage,
                isFixedLayout: epubAsset.isFixedLayout,
                isRightToLeft: epubAsset.isRightToLeft,
                layoutDimensions: epubAsset.layoutDimensions,
                preferenceObject: epubAsset.preferenceObject,
                printPageNumber: epubAsset.printPageNumber
              }}
              config={
                (titleType === constants.CONTENT_TYPE_RPLUS_PDF || titleType === constants.CONTENT_TYPE_RPLUS_EPUB) &&
                resourceCount
                  ? this.rPlusConfig
                  : this.vegaConfig
              }
              env={env.ENVIRONMENT}
              menuIconsStart={
                this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                  ? null
                  : this.menuIconsStart()
              }
              menuIconsEnd={
                this.contentType === constants.BOOK_CONTENT_TYPE.EPUB || this.contentType === 'RPLUS_EPUB'
                  ? null
                  : this.menuIconsEnd()
              }
              onBackNavClick={this.handleBackNavigationClick}
              onNavigationChange={this.handleNavigationChange}
              pageId={this.pageId}
              pagePlaylist={pagePlaylistData}
              pdfCallbacks={this.pdfCallbacks}
              tocData={tocData}
              updateHotspotData={updateHotspotData}
              drawerView={drawerView}
            />
          </ResourceContext>
        )}
        {showHotspotModal && (
          <HotspotDialog
            data={this.createEditRegion}
            closeEditHotspot={this.closeHotspotModal}
            disabledProps={disabledArr}
            requiredProps={requiredArr}
            saveHotspot={this.saveHotspot}
            deleteHotspot={this.deleteHotspot}
            preUpdateHotspot={this.setHotSpotData}
          />
        )}
        {(saveHotspotStatus.isPending || deleteHotspotStatus.isPending) && <Loader />}
        {showHotspotPreview && (
          <HotspotPreview
            basePath={basepath}
            closePreviewHotspot={this.closePreviewModal}
            goToPageNumber={this.handleNavigationChange}
            regionData={this.createEditRegion}
            pagePlaylist={asset.slates}
          />
        )}
        {isUnsupportedHotspot && <UnSupportedPreview open closeDialog={this.closeUnsupportedPreviewDialog} />}
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(PdfViewer)));
