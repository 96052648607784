/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import Framework, { BaseService } from '@greenville/framework';
import { RESOURCE_REQUESTED } from '../../../common/constants';

export default class ResourceService extends BaseService {
  constructor() {
    super(RESOURCE_REQUESTED, 'resourceStatus');
  }

  handleEvent(eventName, eventData) {
    return this.getClient('evergreen').get(`/${eventData.gbid}/resources`);
  }

  handleResponse(response) {
    // eslint-disable-line class-methods-use-this
    const data = { resourceList: response.data };
    Framework.getStoreRegistry().getStore('resourcePanel').set(data.resourceList);
  }
}
