/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { types } from 'mobx-state-tree';
import Framework from '@greenville/framework';

import { RESOURCE_REQUESTED, RESOURCE_FETCHED } from '../../../common/constants';

/**
 * A mobx model for book
 *
 */
const Subtopic = types.model('Subtopic', {
  contentTypeValue: types.optional(types.maybeNull(types.string)),
  description: types.optional(types.maybeNull(types.string)),
  downloadLink: types.optional(types.maybeNull(types.string)),
  fileType: types.optional(types.maybeNull(types.string)),
  isDownloadable: types.optional(types.boolean, false),
  isUserDownloadable: types.optional(types.boolean, false),
  launchBrowser: types.optional(types.boolean, false),
  name: types.optional(types.maybeNull(types.string)),
  pageId: types.optional(types.maybeNull(types.string)),
  sizeInMB: types.optional(types.maybeNull(types.union(types.string, types.integer, types.number))),
  url: types.optional(types.maybeNull(types.string))
});
const relatedTo = types.model('relatedTo', {
  productId: types.maybeNull(types.union(types.string, types.integer, types.number)),
  resourceType: types.maybeNull(types.union(types.string, types.integer, types.number))
});

const Resource = types.model('Resource', {
  limitByRoles: types.maybeNull(types.array(types.string)),
  name: types.maybeNull(types.union(types.string, types.integer, types.number)),
  relatedTo: types.array(relatedTo),
  subtopic: types.array(Subtopic)
});

/**
 * A mobx model for bookshelf
 *
 */
const ResourcePanel = types
  .model(
    'ResourcePanel',
    {
      resourceList: types.array(Resource)
    }
    // eslint-disable-next-line no-unused-vars
  )
  .actions(() => ({
    /* eslint-disable no-param-reassign */
    fetch(id) {
      const request = {
        gbid: id
      };
      Framework.getEventManager().publish(RESOURCE_REQUESTED, request);
    },
    set(data) {
      const newResources = Object.assign([], data);

      Framework.getEventManager().publish(RESOURCE_FETCHED, newResources);
    }
    /* eslint-enable no-param-reassign */
  }));

export default ResourcePanel;
