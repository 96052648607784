/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import { shapes } from '@greenville/framework';
import PropTypes from 'prop-types';
// import SearchIcon from '@material-ui/icons/Search';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Grid, Button, Typography } from '@material-ui/core';
import TooltipCustom from '../../../../common/components/TooltipCustom';

const styles = (theme) => ({
  root: {
    width: '98%',
    padding: theme.spacing(0, 1)
  },
  marginBottom: {
    marginBottom: 10
  },
  formControlInput: {
    maxWidth: 240,
    marginBottom: 10
  },
  paddingRadioGrp: {
    padding: '0 6px'
  },
  labelRadioGrp: {
    fontSize: 14
  },
  paddingTopZero: {
    paddingTop: 0
  },
  alignForms: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonChoose: {
    alignSelf: 'center'
  },
  uploadButton: {
    paddingTop: theme.spacing(1)
  },
  UploadFile: {
    width: '100%'
  },
  buttonCreate: {
    marginLeft: '10px'
  }
});

const UploadSourceFile = ({
  language,
  classes,
  handleOpenUploadContent,
  chooseFileData,
  DBFlag,
  selectedFileValue,
  showuPDF,
  cmSubBusinessUnitName,
  handleCreate,
  data
}) => {
  return (
    <>
      <Grid container align="center" direction="column">
        <Grid>
          <Typography align="center" variant="h5" className={classes.UploadFile}>
            {DBFlag ? (
              <FormattedMessage {...language.getText('contentmanager.UPLOAD_TEXT_EPUB')} />
            ) : (
              <FormattedMessage {...language.getText('contentmanager.UPLOAD_TEXT')} />
            )}
          </Typography>
          {selectedFileValue && (
            <Typography>
              Selected File: <b>{selectedFileValue}</b>
            </Typography>
          )}
        </Grid>

        <Grid className={classes.uploadButton}>
          <TooltipCustom title={<FormattedMessage {...language.getText('contentmanager.CHOOSEFILES')} />}>
            <ButtonGroup disableElevation variant="contained" color="primary">
              <Button
                variant="outlined"
                color="primary"
                disabled={
                  data.identifier === '' ||
                  data.businessUnit === '' ||
                  data.subBusinessUnit === '' ||
                  data.directory === ''
                }
                onClick={handleOpenUploadContent()}
              >
                <FormattedMessage {...language.getText('contentmanager.CHOOSEFILES')} />
              </Button>
              {showuPDF && (
                <Button disabled={!chooseFileData} variant="outlined" color="primary">
                  <FormattedMessage {...language.getText('eTextupdf.CREATE_UPDF')} />
                </Button>
              )}
            </ButtonGroup>
          </TooltipCustom>
          {/* <TooltipCustom title={<FormattedMessage {...language.getText('contentmanager.CREATE_VALIDATE_BTN')} />}> */}
          {(cmSubBusinessUnitName === 'australia' || cmSubBusinessUnitName === 'ell') && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonCreate}
              onClick={(event) => handleCreate(event)}
            >
              <FormattedMessage {...language.getText('contentmanager.CREATE_VALIDATE_BTN')} />
            </Button>
          )}
          {/* </TooltipCustom> */}
        </Grid>
        {/* {chooseFileData && (
          <Grid>
            <Button variant="outlined" color="primary">
              text value
            </Button>
          </Grid>
        )} */}
      </Grid>
      {/* <Grid>
        <Button variant="outlined" color="primary" onClick={handleOpenUploadContent()}>
          <FormattedMessage {...language.getText('contentmanager.CHOOSEFILES')} />
        </Button>
      </Grid>
    </Grid> */}
    </>
  );
};

UploadSourceFile.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  data: PropTypes.object.isRequired,
  showuPDF: PropTypes.bool.isRequired,
  handleOpenUploadContent: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  chooseFileData: PropTypes.string.isRequired,
  DBFlag: PropTypes.bool.isRequired,
  selectedFileValue: PropTypes.string.isRequired,
  cmSubBusinessUnitName: PropTypes.string.isRequired
};

export default withStyles(styles)(inject('language')(UploadSourceFile));
